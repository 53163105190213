var encryptPassphrase = "Cobra Zero by Dowino";

function encryptProgression(scoreData, uid)
{
    scoreData.scoreHashes = [CryptoJS.MD5(scoreData.currentScore).toString()];
    for(var score in scoreData.scores)
        scoreData.scoreHashes.push(CryptoJS.MD5(score).toString());
    localStorage.setItem("dataUser_v2_" + uid, CryptoJS.AES.encrypt(JSON.stringify(scoreData), encryptPassphrase).toString());
}

function decryptProgression(uid)
{
    // console.log(CryptoJS.AES.decrypt(CryptoJS.AES.encrypt("This is a test", encryptKey), encryptKey).toString(CryptoJS.enc.Utf8));
    const data = localStorage.getItem("dataUser_v2_" + uid);
    const oldData = localStorage.getItem("dataUser_" + uid);
    
    if(data != null)
        return JSON.parse(CryptoJS.AES.decrypt(data, encryptPassphrase).toString(CryptoJS.enc.Utf8));
    else if(oldData != null)
        return JSON.parse(oldData);
    return null;
}

function getScoreData(uid)
{
    var encryptKey = CryptoJS.enc.Utf8.parse("17EVjmb6v0mWNMQ65CDpmQ9mMq2ChzX4");
    
    const data = localStorage.getItem("dataUser_v2_" + uid);
    if(data != null)
    {
        saveData = JSON.parse(CryptoJS.AES.decrypt(data, encryptPassphrase).toString(CryptoJS.enc.Utf8));
        
        var iv = CryptoJS.lib.WordArray.random(16);
        var cypherConfig = {
            iv: iv,
            mode: CryptoJS.mode.CBC,
        };
        var scoresEncryptData = CryptoJS.AES.encrypt(JSON.stringify(saveData.scores), encryptKey, cypherConfig);
        var scoresData = iv.concat(scoresEncryptData.ciphertext).toString(CryptoJS.enc.Base64);
        
        iv = CryptoJS.lib.WordArray.random(16);
        cypherConfig = {
            iv: iv,
            mode: CryptoJS.mode.CBC,
        };

        var quizScoresEncryptData = CryptoJS.AES.encrypt(JSON.stringify(saveData.quizScores), encryptKey, cypherConfig);
        var quizScoresData = iv.concat(quizScoresEncryptData.ciphertext).toString(CryptoJS.enc.Base64);
        
        iv = CryptoJS.lib.WordArray.random(16);
        cypherConfig = {
            iv: iv,
            mode: CryptoJS.mode.CBC,
        };

        var miScoreEncryptData = CryptoJS.AES.encrypt(saveData.miScore.toString(), encryptKey, cypherConfig);
        var miScoreData = iv.concat(miScoreEncryptData.ciphertext).toString(CryptoJS.enc.Base64);

        result = {
            encrypted: scoresEncryptData.toString(),
            scores: scoresData,
            quizScores: quizScoresData,
            miScore: miScoreData,
        }
        return result;
    }
    return {};
}
